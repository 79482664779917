export default {
  root: ({ props, context }) => ({
    class: [
      'm-0',
      'font-sans text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 border border-gray-400 dark:border-primary-900/40 transition-colors duration-200 appearance-none rounded',
      {
        'focus:outline-none focus-within:border-primary focus-within:border-2': !context.disabled,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
      },
      {
        'text-lg px-4 py-4': props.size === 'large',
        'text-xs px-2 py-2': props.size === 'small',
        'px-3 py-2 text-base': props.size == null,
      },
    ],
  }),
}
