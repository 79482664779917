import revive_payload_client_jO772nxQoc from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.11_@unocss+reset_nueb4arcdcn3xqfowqbzrwghdy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_K5MhBrJEWS from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.11_@unocss+reset_nueb4arcdcn3xqfowqbzrwghdy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_a2bY2PaXPx from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.11_@unocss+reset_nueb4arcdcn3xqfowqbzrwghdy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VBryAC1qpB from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.11_@unocss+reset_nueb4arcdcn3xqfowqbzrwghdy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_PARn4eASqw from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.11_@unocss+reset_nueb4arcdcn3xqfowqbzrwghdy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_hYeJZNnR9L from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.2_typescript@4.9.5_vue@3.4.29_typescript@4.9.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_hBlFFaQOSg from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.11_@unocss+reset_nueb4arcdcn3xqfowqbzrwghdy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_32ogCXhpka from "/app/node_modules/.pnpm/@primevue+nuxt-module@4.0.4_@babel+parser@7.25.0_magicast@0.3.4_rollup@4.17.2_vue@3.4.29_typescript@4.9.5_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import plugin_client_0es7dvT31Z from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.17.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_i9IwtPGKP0 from "/app/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_@tanstack+vue-query@5.45.0_vue@3.4.29_typescript@4.9.5___nu_i4uddocnzw4oopehmctbreendu/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import composition_GNoLB1dsKu from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.9_rollup@4.17.2_vue-router@4.3.2_vue@3.4.29_typescript@4.9.5___vue@3.4.29_typescript@4.9.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_ST3kQ9InBb from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.9_rollup@4.17.2_vue-router@4.3.2_vue@3.4.29_typescript@4.9.5___vue@3.4.29_typescript@4.9.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_u9fWJg3J9t from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.5.0_@opentelemetry+api@1.9.0_encoding@0.1.13_magicast@0.3.4_next-auth@4_rpql2t5w6njr33uqpfveqxewx4/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/app/.nuxt/formkitPlugin.mjs";
import plugin_0oemgopsvA from "/app/node_modules/.pnpm/nuxt-icon@1.0.0-beta.5_magicast@0.3.4_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2._vbylif2zooqwabiqe4tc7u5wa4/node_modules/nuxt-icon/dist/runtime/plugin.js";
import chunk_reload_client_2W2nS0FORY from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.12.11_@unocss+reset_nueb4arcdcn3xqfowqbzrwghdy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import appState_6zDlVSUkMw from "/app/plugins/appState.ts";
import autoanimate_XXcG14nh7J from "/app/plugins/autoanimate.ts";
import casl_QTBkbB6FCs from "/app/plugins/casl.ts";
import grid_client_svLOeFmqDG from "/app/plugins/grid.client.ts";
import highCharts_client_NZqQlQ5SOs from "/app/plugins/highCharts.client.ts";
import novu_client_AMvynteNKX from "/app/plugins/novu.client.ts";
import persistedstate_o0qkr1uoQ4 from "/app/plugins/persistedstate.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_jO772nxQoc,
  unhead_K5MhBrJEWS,
  router_a2bY2PaXPx,
  payload_client_VBryAC1qpB,
  check_outdated_build_client_PARn4eASqw,
  plugin_vue3_hYeJZNnR9L,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hBlFFaQOSg,
  primevue_plugin_egKpok8Auk,
  plugin_client_32ogCXhpka,
  plugin_client_0es7dvT31Z,
  plugin_i9IwtPGKP0,
  composition_GNoLB1dsKu,
  i18n_ST3kQ9InBb,
  plugin_u9fWJg3J9t,
  formkitPlugin_pZqjah0RUG,
  plugin_0oemgopsvA,
  chunk_reload_client_2W2nS0FORY,
  appState_6zDlVSUkMw,
  autoanimate_XXcG14nh7J,
  casl_QTBkbB6FCs,
  grid_client_svLOeFmqDG,
  highCharts_client_NZqQlQ5SOs,
  novu_client_AMvynteNKX,
  persistedstate_o0qkr1uoQ4,
  sentry_client_shVUlIjFLk
]