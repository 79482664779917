export default {
    root: {
      class: ['inline-flex items-center', 'bg-gray-200 text-gray-800', 'px-3', 'dark:text-white/80 dark:bg-gray-900']
    },
    label: {
      class: 'leading-6 mt-1.5 mb-1.5 lg:mr-4 pt-1 font-medium'
    },
    image: {
      class: ['w-8 h-8 -ml-2 mr-2', 'rounded-full']
    },
    removeIcon: {
      class: ['inline-block', 'rounded-md leading-6', 'w-4 h-4', 'transition duration-200 ease-in-out', 'cursor-pointer']
    }
  }
  