
export default {
    root: {
        class: ['min-w-[12rem]', 'rounded-lg', 'bg-surface-0 dark:bg-surface-700', 'dark:text-white/80', 'border', 'au-shadow']
    },
    list: {
        class: ['list-none', 'm-0', 'rounded-lg', 'p-1', 'outline-none']
    },
    item: {
        class: 'first:mt-0 mt-1'
    },
    itemContent: ({ context }) => ({
        class: [
          //Shape
          'rounded-none',
          // Colors
          'text-slate-600 dark:text-surface-0',
          // Transitions
          'transition-shadow',
          'duration-200',
          // States
          {
            'hover:bg-surface-300/20 dark:hover:bg-surface-400/10': !context.active,
            'hover:bg-highlight-emphasis': context.active
          },
          // Disabled
          { 'opacity-60 pointer-events-none cursor-default': context.disabled }
        ]
    }),
    itemLink: {
        class: ['relative', 'flex', 'items-center', 'py-2', 'px-3', 'font-semibold', 'no-underline', 'overflow-hidden', 'cursor-pointer', 'select-none']
    },
    itemIcon: {
        class: ['mr-2', 'leading-6']
    },
    itemLabel: {
        class: ['leading-6', 'text-sm mt-1']
    },
    submenuLabel: {
        class: ['font-bold', 'm-0', 'py-2 px-3', 'rounded-tl-none', 'rounded-tr-none', 'bg-surface-0 dark:bg-surface-900', 'text-surface-400 dark:text-surface-600']
    },
    transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0'
    }
}
