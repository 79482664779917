<script setup lang="ts">
// try importing pv-input-otp directly later
import InputOtp from 'primevue/inputotp'

const props = defineProps({
  context: Object,
})

const code = ref()

function handleInput(e) {
  props.context?.node.input(e.value)
}
</script>

<template>
  <InputOtp
    v-model="code"
    :length="6"
    @change="handleInput"
  >
    <template #default="{ attrs, events, index }">
      <input
        type="text"
        v-bind="attrs"
        class="w-10 h-14 text-xl text-center transition-all border border-gray-400/50 bg-transparent outline-offset-2 rounded-md text-gray-500 focus:outline-2 focus:outline-primary-300"
        v-on="events"
      >
      <div
        v-if="index === 3"
        class="px-3 text-gray-400/60"
      >
        <i class="pi pi-minus" />
      </div>
    </template>
  </InputOtp>
</template>
