// import shortenPath from '@/helpers/shortenPath'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { status, data } = useAuth()
  const { errorToast } = useToast()
  const { get } = useApi()
  const isAuthenticated = status.value === 'authenticated'
  const isGuest = data.value?.isGuest
  const { signOut } = useSignOut()
  const { isRouteAllowed, defaultRedirectRoute } = useHierarchicalPermissions()

  if (from.path === '/ap')
    return

  if ((isAuthenticated && !isGuest) && to.path === ('/login'))
    return navigateTo(defaultRedirectRoute.value)

  if ((!isAuthenticated || (isAuthenticated && isGuest)) && to.path.includes('secure'))
    return navigateTo(`/login?redirect=${to.path}`)

  if (!isAuthenticated && (!to.path.includes('secure') && to.path !== '/'))
    return

  const nuxtApp = useNuxtApp()
  const ability: any = nuxtApp.vueApp.config.globalProperties.$ability

  // if refresh check the session and set again abilities
  if ((from.path === to.path && import.meta.client) && (JSON.stringify(from.query) === JSON.stringify(to.query))) {
    try {
      await get('ping')
      ability.update(data.value?.user?.abilities)
    }
    catch (e) {
      await signOut({ redirect: false })
      return navigateTo('/login')
    }
  }

  // TODO: Ask will be the default route is case there are no abilities
  // // always allow to go to the dashboard & feedback
  // if (to.path === '/secure' || to.path === '/secure/feedback')
  //   return

  // check permission an redirect to default route
  if (import.meta.client && !isRouteAllowed(to.path)) {
    if (isGuest)
      return

    if (from.path !== '/login')
      errorToast('You have no access to this module.')

    if (from.path === defaultRedirectRoute.value)
      return abortNavigation()

    return navigateTo(defaultRedirectRoute.value)
  }

  // fallbacks for parent routes
  if (to.path === '/secure/account')
    return navigateTo('/secure/account/profile')

  // 3. Otherwise: Check permissions and redirect to home page
  // if (to.path.startsWith('/secure') && isAuthenticated && process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {

  // }
})
