<script setup>
import { Codemirror } from 'vue-codemirror'
import { json } from '@codemirror/lang-json'
import { oneDark } from '@codemirror/theme-one-dark'

const props = defineProps({
  context: Object,
})

const extensions = [json(), oneDark]
const code = ref(JSON.stringify(props.context?._value, null, 2))

// Codemirror EditorView instance ref
const view = shallowRef()
function handleReady(payload) {
  view.value = payload.view
}

function handleInput(e) {
  try {
    props.context?.node.input(JSON.parse(e))
  }
  catch (error) {}
}
</script>

<template>
  <Codemirror
    v-model="code"
    placeholder="Enter code here..."
    :style="{ height: '300px', width: '100%' }"
    :autofocus="true"
    :indent-with-tab="true"
    :tab-size="2"
    :extensions="extensions"
    @ready="handleReady"
    @change="handleInput"
  />
</template>
