<script setup lang="ts">
import type { EditorSelectionChangeEvent } from 'primevue/editor'

const props = defineProps({
  context: Object,
})

const context = props.context
const attrs = computed(() => context?.attrs ?? {})
const editorStyle = computed(() => context?.attrs.editorStyle ?? context?.attrs['editor-style'] ?? null)
const pt = computed(() => context?.attrs.pt ?? {})

function handleInput(e: any) {
  context?.node.input(e.htmlValue)
}

function handleSelection(e: EditorSelectionChangeEvent) {
  if (e.range === null)
    context?.handlers.blur(e.htmlValue)
}

// const styleClass = computed(() => (context?.state.validationVisible && !context?.state.valid) ? `${attrs.value?.class} p-invalid` : attrs.value?.class)
</script>

<template>
  <div class="p-formkit">
    <pv-editor
      :id="attrs?.id"
      v-model="context._value"
      :disabled="attrs?._disabled ?? false"
      :readonly="attrs?._readonly ?? false"
      :editor-style="editorStyle"
      :class="attrs.class"
      :tabindex="attrs?.tabindex"
      :aria-labelledby="attrs?.ariaLabelledby"
      :placeholder="attrs?.placeholder"
      :formats="attrs?.formats"
      :modules="attrs?.modules"
      :pt="pt"
      :pt-options="attrs?.ptOptions"
      :unstyled="attrs?.unstyled ?? false"
      @text-change="handleInput"
      @selection-change="handleSelection"
    >
      <template #toolbar>
        <!-- NOTE: For now are just added the needed ones but in the future more options could be needed, view Quill formats to add more -->
        <span class="ql-formats">
          <button
            v-tooltip.bottom="'Bold'"
            class="ql-bold rounded"
          />
          <button
            v-tooltip.bottom="'Italic'"
            class="ql-italic rounded ml-4"
          />
          <button
            v-tooltip.bottom="'Underline'"
            class="ql-underline rounded ml-4"
          />
          <button
            v-tooltip.bottom="'Strike'"
            class="ql-strike rounded ml-4"
          />
          <button
            v-tooltip.bottom="'Bullet list'"
            class="ql-list rounded ml-4"
            value="bullet"
          />
          <button
            v-tooltip.bottom="'Ordered list'"
            class="ql-list rounded ml-4"
            value="ordered"
          />
        </span>
      </template>
    </pv-editor>
  </div>
</template>

<style>
.ql-formats{
  button {
    height: 28px !important;
    padding: 4px !important;
  }
  .ql-stroke, .ql-fill {
    stroke: theme('colors.gray.300') !important;
  }

  .ql-strike{
    .ql-fill{
      fill: theme('colors.gray.300') !important;
      stroke: theme('colors.gray.300') !important;
    }
  }

  svg path{
    stroke: theme('colors.gray.300') !important;
  }

  .ql-active{
    background-color: theme('colors.slate.100') !important;
    .ql-stroke, .ql-fill {
      stroke: theme('colors.slate.600') !important;
    }
    &.ql-strike{
      .ql-stroke, .ql-thin, .ql-fill{
        fill: theme('colors.slate.600') !important;
        stroke: theme('colors.slate.600') !important;
      }
    }
  }
}

.p-editor .p-editor-toolbar.ql-snow{
  border: 1px solid theme('colors.slate.200') !important;
  border-bottom: 0 !important;
}

.p-editor .p-editor-content.ql-snow{
  border: 1px solid theme('colors.slate.200') !important;
  border-top: 0 !important;
}
.ql-editor.ql-blank::before{
  font-size: 1rem;
  color: theme('colors.gray.400') !important;
}
</style>
