import toasteventbus from 'primevue/toasteventbus'

export function useToast() {
  function errorToast(
    detail = 'There was a problem with your request.Please try again!',
    summary = 'Error',
    life = 4000,
  ) {
    toasteventbus.emit('add', { severity: 'error', summary, detail, life })
  }

  function successToast(
    detail = 'The data has been sent successfully',
    summary = 'Success',
    life = 3000,
  ) {
    toasteventbus.emit('add', { severity: 'success', summary, detail, life })
  }
  function infoToast(
    detail: string,
    summary = 'Info',
    life = 3000,
  ) {
    toasteventbus.emit('add', { severity: 'info', summary, detail, life })
  }

  return { errorToast, successToast, infoToast }
}
