<script setup lang="ts">
import { DatePicker } from 'v-calendar'
import { format } from 'date-fns'
import 'v-calendar/style.css'

const props = defineProps({
  context: Object,
  timezone: {
    type: String,
    default: null,
  },
})
const attrs = computed(() => props.context?.attrs)

const userTimezone = computed(() => attrs.value.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
const calendarCmp = ref()
const inputValue = ref()

function handleSelect(value) {
  if (!value) {
    return
  }
  if (!attrs.value.range) {
    props.context?.node.input(value)
  }
  else {
    if (!value.start || !value.end) {
      return
    }
    const dates = [format(value.start, 'MM/dd/Y'), format(value.end, 'MM/dd/Y')]
    props.context?.node.input(dates)
  }
}

async function moveToDate() {
  if (calendarCmp.value) {
    await calendarCmp.value.move(props.context?._value)
  }
}

watch(() => props.context?._value, (newVal) => {
  if (!attrs.value?.range) {
    inputValue.value = newVal
  }
  else if (!newVal) {
    return
  }
  else {
    inputValue.value = { start: new Date(newVal[0]), end: new Date(newVal[1]) }
    return
  }
  moveToDate()
}, { immediate: true })
</script>

<template>
  <DatePicker
    ref="calendarCmp"
    v-model="inputValue"
    :is-range="attrs.range"
    :mode="attrs.mode ?? 'dateTime'"
    color="teal"
    :disabled-dates="attrs['disabled-dates']"
    :min-date="attrs['min-date']"
    :rules="attrs.rules"
    :timezone="userTimezone"
    is24hr
    @update:model-value="handleSelect"
  >
    <template #footer>
      <div
        v-if="attrs.range"
        class="flex justify-end px-4 pb-3"
      >
        <ap-button
          :label="$t('dashboard.charts.today')"
          button-class="w-full !py-2"
          label-class="!mt-0"
          size="small"
          @click="inputValue = { start: new Date(), end: new Date() }"
        />
      </div>
      <div
        v-if="attrs.timezone"
        class="text-gray-500 my-2 text-center"
      >
        {{ $t('shared.form.timezone') }}: {{ userTimezone }}
      </div>
    </template>
  </DatePicker>
</template>
