export default {
  root: {
    class: ['bg-white dark:bg-gray-900 border border-gray-400 dark:border-primary-900/40 transition-colors duration-200 ease-in-out rounded-md', 'w-full md:w-56'],
  },
  wrapper: {
    class: 'overflow-auto',
  },
  list: {
    class: 'py-3 list-none m-0',
  },
  item: ({ context }) => ({
    class: [
      'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
      'm-0 p-3 border-0  transition-shadow duration-200 rounded-none',
      {
        'text-gray-700 hover:text-gray-700 hover:bg-gray-200 dark:text-white/80 dark:hover:bg-gray-800': !context.focused && !context.selected,
        'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90 hover:text-gray-700 hover:bg-gray-200 dark:text-white/80 dark:hover:bg-gray-800': context.focused && !context.selected,
        'bg-primary-100 text-primary-700 dark:bg-primary-400 dark:text-white/80': context.focused && context.selected,
        'bg-primary-50 text-primary-700 dark:bg-primary-300 dark:text-white/80': !context.focused && context.selected,
      },
    ],
  }),
  itemgroup: {
    class: ['m-0 p-3 text-gray-800 bg-white font-bold', 'dark:bg-gray-900 dark:text-white/80', 'cursor-auto'],
  },
  header: {
    class: ['p-3 border-b border-gray-300 text-gray-700 bg-gray-100 mt-0 rounded-tl-lg rounded-tr-lg', 'dark:bg-gray-800 dark:text-white/80 dark:border-primary-900/40'],
  },
  filtercontainer: {
    class: 'relative',
  },
  filterinput: {
    class: [
      'pr-7 -mr-7',
      'w-full',
      'font-sans text-base text-gray-700 bg-white py-3 px-3 border border-gray-300 transition duration-200 rounded-lg appearance-none',
      'dark:bg-gray-900 dark:border-primary-900/40 dark:hover:border-primary-300 dark:text-white/80',
      'hover:border-primary-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
    ],
  },
  filtericon: {
    class: '-mt-2 absolute top-1/2 right-3 text-gray-500',
  },
}
