import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import accessibility from 'highcharts/modules/accessibility'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  accessibility(Highcharts)
  Highcharts.theme = {
    colors: [
      '#167275',
      '#F1C85C',
      '#B06165',
      '#A7D0D1',
      '#6AA5A7',
      '#7C7F82',
    ],
    chart: {
      backgroundColor: 'white',
      style: {
        fontFamily: 'overpass',
      },
    },
    title: {
      text: undefined,
      align: 'left',
    },
    subtitle: {
      text: undefined,
      align: 'left',
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      enabled: true,
    },
    xAxis: {
      gridLineWidth: 1,
      gridLineColor: '#F3F3F3',
      lineColor: '#F3F3F3',
      minorGridLineColor: '#F3F3F3',
      tickColor: '#F3F3F3',
      tickWidth: 1,
    },
    yAxis: {
      gridLineColor: '#F3F3F3',
      lineColor: '#F3F3F3',
      minorGridLineColor: '#F3F3F3',
      tickColor: '#F3F3F3',
      tickWidth: 1,
    },
  }

  Highcharts.setOptions({
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    lang: {
      noData: 'test',
      // noData: '<div class="flex flex-col justify-center items-center mt-6"> <img src="~assets/undraw/u_no_data.svg" alt="No data found" class="w-1/2 xl:w-1/5 max-w-xl"> <div class="mt-6"> test </div> </div>',
      thousandsSep: ',',
    },
    noData: {
      useHTML: true,
      position: {
        verticalAlign: 'top',
        y: 50,
      },
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#6b6f74',
      },
    },
  })

  Highcharts.setOptions(Highcharts.theme)

  nuxtApp.vueApp.use(HighchartsVue, { highcharts: Highcharts })
})
