<script setup lang="ts">
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: null,
  },
  labelClass: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  iconClass: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  severity: {
    type: String,
    default: '',
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  text: {
    type: Boolean,
    default: false,
  },
  raised: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  buttonClass: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['click'])

const localSeverity = computed(() => !props.severity || props.severity === 'primary' ? null : props.severity)
</script>

<template>
  <pv-button
    v-if="label"
    label=""
    :severity="localSeverity"
    :size="size"
    :text="text"
    :raised="raised"
    :outlined="outlined"
    :disabled="disabled"
    :class="[{ '!py-2': icon }, buttonClass]"
    :rounded="rounded"
    @click="emit('click', $event)"
  >
    <div class="flex items-center gap-2">
      <Icon
        v-if="icon"
        :name="`ap:${icon}`"
        class="w-5 h-5"
        :class="iconClass"
      />
      <span
        class="capitalize mt-1"
        :class="labelClass"
      >{{ label }}</span>
    </div>
  </pv-button>
  <pv-button
    v-else-if="icon && !label"
    :severity="localSeverity"
    :size="size"
    :text="text"
    :outlined="outlined"
    :raised="raised"
    :rounded="rounded"
    :class="buttonClass"
    :disabled="disabled"
    @click="emit('click', $event)"
  >
    <Icon
      :name="`ap:${icon}`"
      class="w-6 h-6"
      :class="iconClass"
    />
  </pv-button>
</template>
