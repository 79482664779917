import i18n from '@/i18n/index.js'

const { t } = i18n.global

export default [
  {
    group: 'dashboard',
    icon: 'ap:grid',
    name: t('pages.dashboard.title'),
  },
  {
    group: 'quotes',
    name: t('pages.quotes.title'),
    to: 'quotes',
    icon: 'ap:folder-question',
  },
  {
    group: 'projects',
    name: t('pages.projects.title'),
    to: 'projects',
    icon: 'ap:folder-check',
  },
  {
    group: 'templates',
    name: t('pages.templates.title'),
    to: 'templates',
    icon: 'ap:layout-alt-04',
  },
  {
    group: 'invoices',
    name: t('pages.invoices.title'),
    to: 'invoices',
    icon: 'ap:file-02',
  },

  {
    parent: 'account',
    name: t('pages.profile.title'),
    description: t('pages.profile.description'),
    to: 'account/profile',
    icon: 'ap:user-circle',
  },
  {
    parent: 'account',
    name: t('pages.security.title'),
    description: t('pages.security.description'),
    to: 'account/security',
    icon: 'ap:shield-tick',
  },
  {
    parent: 'account',
    name: t('pages.company.title'),
    description: t('pages.company.description'),
    to: 'account/company',
    icon: 'ap:building-07',
  },
  {
    parent: 'account',
    name: t('pages.members.title'),
    description: t('pages.members.description'),
    to: 'account/members',
    icon: 'ap:users-01',
  },
]
