import { genesisIcons } from '@formkit/icons'
import { createInput } from '@formkit/vue'

// import { arrowDown, arrowUp, check, circle, close, down, fileDoc, spinner, star, trash } from '@formkit/icons'
import { createAutoAnimatePlugin } from '@formkit/addons'
import { generateClasses } from '@formkit/themes'
import type { DefaultConfigOptions } from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'
import { createLocalStoragePlugin } from '@formkit/addons'
import theme from './formkit.theme.js'
import VCalendar from '@/components/form/VCalendar.vue'
import FileUploader from '@/components/form/FileUploader.vue'
import Editor from '@/components/form/Editor.vue'
import codeEditor from '@/components/form/CodeEditor.vue'
import OtpInput from '@/components/form/OtpInput.vue'

import { cron } from '@/helpers/formkitValidation.js'

// import genesis from '@formkit/themes/tailwindcss/genesis'

// import '@formkit/themes/genesis'
// import '@formkit/pro/genesis'

const pro = createProPlugin('fk-6f383616a34', inputs)
const codeblock = createInput(codeEditor)
const vCalendar = createInput(VCalendar, {})
const otp = createInput(OtpInput, {})
const fileUploader = createInput(FileUploader, {
  props: ['languages', 'office', 'extWarning', 'maxFileSize'],
})
const editor = createInput(Editor, {
  props: ['id'],
})

const config: DefaultConfigOptions = {
  plugins: [
    pro,
    createAutoAnimatePlugin(
      {
        /* optional AutoAnimate config */
        // default:
        duration: 250,
        easing: 'ease-in-out',
        delay: 0,
      },
      {
        /* optional animation targets object */
        // default:
        global: ['outer', 'inner'],
        form: ['form'],
        repeater: ['items'],
        autocomplete: ['selections'],
      },
    ),
    createLocalStoragePlugin({
      prefix: 'formkit',
      maxAge: 1000 * 60 * 60, // 1 hour
    }),
  ],
  config: {
    classes: generateClasses(theme),
    validationVisibility: 'dirty', // This is to avoid the miss clicking on dropdowns
  },
  icons: {
    ...genesisIcons,
  },
  inputs: {
    codeblock,
    vCalendar,
    otp,
    fileUploader,
    editor,
  },
  rules: { cron },
}

export default config
