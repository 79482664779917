import { useAuthStore } from '@/stores/authStore'

export function useSignOut() {
  const { signOut: logOut } = useAuth()
  const queryClient = useQueryClient()

  async function signOut(options) {
    localStorage.removeItem('formkit-requestForm')
    queryClient.clear()
    useAuthStore().resetTokens()
    return await logOut(options)
  }

  return {
    signOut,
  }
}
