<script setup>
const pageTitle = useState('pageTitle', () => '')
useHead({
  title: 'AvantPortal',
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
    <PvConfirmDialog />
  </NuxtLayout>
  <PvToast />
</template>

<style>
html,
body,
#__nuxt {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

@media only screen and (min-width: 1536px) {
  html,
  body,
  #__nuxt {
    font-size: 14px;
  }
}

html.dark {
  background: #222;
  color: white;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(0.1rem);
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.6s;
}

.layout-enter-from,
.layout-leave-to {
  opacity: 0;
  filter: blur(0.5rem);
}
</style>
