import { default as apxHNkJeXi4LMeta } from "/app/pages/ap.vue?macro=true";
import { default as forgotNziWY5IfaeMeta } from "/app/pages/forgot.vue?macro=true";
import { default as createf5s5DwAuX2Meta } from "/app/pages/guest/create.vue?macro=true";
import { default as iconsAVqGQpFjv7Meta } from "/app/pages/icons.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as rapidPbK4vmqDKwMeta } from "/app/pages/rapid.vue?macro=true";
import { default as companymn6bSera9uMeta } from "/app/pages/secure/account/company.vue?macro=true";
import { default as membersj0PB6i98usMeta } from "/app/pages/secure/account/members.vue?macro=true";
import { default as profileTz1fso9NbGMeta } from "/app/pages/secure/account/profile.vue?macro=true";
import { default as securityS7kxb2iQEEMeta } from "/app/pages/secure/account/security.vue?macro=true";
import { default as createbE2nW5ONAEMeta } from "/app/pages/secure/create.vue?macro=true";
import { default as dashboardhutafg3dmLMeta } from "/app/pages/secure/dashboard.vue?macro=true";
import { default as indexOa5WLeOM4AMeta } from "/app/pages/secure/index.vue?macro=true";
import { default as indexESzK7aGa0JMeta } from "/app/pages/secure/invoices/index.vue?macro=true";
import { default as _91id_938kHlNBW4V2Meta } from "/app/pages/secure/projects/[id].vue?macro=true";
import { default as indexeUkJKDsrF2Meta } from "/app/pages/secure/projects/index.vue?macro=true";
import { default as _91id_939CD2r2X6n4Meta } from "/app/pages/secure/quotes/[id].vue?macro=true";
import { default as indexLdnizgkY94Meta } from "/app/pages/secure/quotes/index.vue?macro=true";
import { default as templatesEB5DgJAyeGMeta } from "/app/pages/secure/templates.vue?macro=true";
import { default as utilsoKlxMvFT6UMeta } from "/app/pages/secure/utils.vue?macro=true";
export default [
  {
    name: apxHNkJeXi4LMeta?.name ?? "ap___en",
    path: apxHNkJeXi4LMeta?.path ?? "/ap",
    meta: apxHNkJeXi4LMeta || {},
    alias: apxHNkJeXi4LMeta?.alias || [],
    redirect: apxHNkJeXi4LMeta?.redirect,
    component: () => import("/app/pages/ap.vue").then(m => m.default || m)
  },
  {
    name: forgotNziWY5IfaeMeta?.name ?? "forgot___en",
    path: forgotNziWY5IfaeMeta?.path ?? "/forgot",
    meta: forgotNziWY5IfaeMeta || {},
    alias: forgotNziWY5IfaeMeta?.alias || [],
    redirect: forgotNziWY5IfaeMeta?.redirect,
    component: () => import("/app/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: createf5s5DwAuX2Meta?.name ?? "guest-create___en",
    path: createf5s5DwAuX2Meta?.path ?? "/guest/create",
    meta: createf5s5DwAuX2Meta || {},
    alias: createf5s5DwAuX2Meta?.alias || [],
    redirect: createf5s5DwAuX2Meta?.redirect,
    component: () => import("/app/pages/guest/create.vue").then(m => m.default || m)
  },
  {
    name: iconsAVqGQpFjv7Meta?.name ?? "icons___en",
    path: iconsAVqGQpFjv7Meta?.path ?? "/icons",
    meta: iconsAVqGQpFjv7Meta || {},
    alias: iconsAVqGQpFjv7Meta?.alias || [],
    redirect: iconsAVqGQpFjv7Meta?.redirect,
    component: () => import("/app/pages/icons.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___en",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: rapidPbK4vmqDKwMeta?.name ?? "rapid___en",
    path: rapidPbK4vmqDKwMeta?.path ?? "/rapid",
    meta: rapidPbK4vmqDKwMeta || {},
    alias: rapidPbK4vmqDKwMeta?.alias || [],
    redirect: rapidPbK4vmqDKwMeta?.redirect,
    component: () => import("/app/pages/rapid.vue").then(m => m.default || m)
  },
  {
    name: companymn6bSera9uMeta?.name ?? "secure-account-company___en",
    path: companymn6bSera9uMeta?.path ?? "/secure/account/company",
    meta: companymn6bSera9uMeta || {},
    alias: companymn6bSera9uMeta?.alias || [],
    redirect: companymn6bSera9uMeta?.redirect,
    component: () => import("/app/pages/secure/account/company.vue").then(m => m.default || m)
  },
  {
    name: membersj0PB6i98usMeta?.name ?? "secure-account-members___en",
    path: membersj0PB6i98usMeta?.path ?? "/secure/account/members",
    meta: membersj0PB6i98usMeta || {},
    alias: membersj0PB6i98usMeta?.alias || [],
    redirect: membersj0PB6i98usMeta?.redirect,
    component: () => import("/app/pages/secure/account/members.vue").then(m => m.default || m)
  },
  {
    name: profileTz1fso9NbGMeta?.name ?? "secure-account-profile___en",
    path: profileTz1fso9NbGMeta?.path ?? "/secure/account/profile",
    meta: profileTz1fso9NbGMeta || {},
    alias: profileTz1fso9NbGMeta?.alias || [],
    redirect: profileTz1fso9NbGMeta?.redirect,
    component: () => import("/app/pages/secure/account/profile.vue").then(m => m.default || m)
  },
  {
    name: securityS7kxb2iQEEMeta?.name ?? "secure-account-security___en",
    path: securityS7kxb2iQEEMeta?.path ?? "/secure/account/security",
    meta: securityS7kxb2iQEEMeta || {},
    alias: securityS7kxb2iQEEMeta?.alias || [],
    redirect: securityS7kxb2iQEEMeta?.redirect,
    component: () => import("/app/pages/secure/account/security.vue").then(m => m.default || m)
  },
  {
    name: createbE2nW5ONAEMeta?.name ?? "secure-create___en",
    path: createbE2nW5ONAEMeta?.path ?? "/secure/create",
    meta: createbE2nW5ONAEMeta || {},
    alias: createbE2nW5ONAEMeta?.alias || [],
    redirect: createbE2nW5ONAEMeta?.redirect,
    component: () => import("/app/pages/secure/create.vue").then(m => m.default || m)
  },
  {
    name: dashboardhutafg3dmLMeta?.name ?? "secure-dashboard___en",
    path: dashboardhutafg3dmLMeta?.path ?? "/secure/dashboard",
    meta: dashboardhutafg3dmLMeta || {},
    alias: dashboardhutafg3dmLMeta?.alias || [],
    redirect: dashboardhutafg3dmLMeta?.redirect,
    component: () => import("/app/pages/secure/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexOa5WLeOM4AMeta?.name ?? "secure___en",
    path: indexOa5WLeOM4AMeta?.path ?? "/secure",
    meta: indexOa5WLeOM4AMeta || {},
    alias: indexOa5WLeOM4AMeta?.alias || [],
    redirect: indexOa5WLeOM4AMeta?.redirect,
    component: () => import("/app/pages/secure/index.vue").then(m => m.default || m)
  },
  {
    name: indexESzK7aGa0JMeta?.name ?? "secure-invoices___en",
    path: indexESzK7aGa0JMeta?.path ?? "/secure/invoices",
    meta: indexESzK7aGa0JMeta || {},
    alias: indexESzK7aGa0JMeta?.alias || [],
    redirect: indexESzK7aGa0JMeta?.redirect,
    component: () => import("/app/pages/secure/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_938kHlNBW4V2Meta?.name ?? "secure-projects-id___en",
    path: _91id_938kHlNBW4V2Meta?.path ?? "/secure/projects/:id()",
    meta: _91id_938kHlNBW4V2Meta || {},
    alias: _91id_938kHlNBW4V2Meta?.alias || [],
    redirect: _91id_938kHlNBW4V2Meta?.redirect,
    component: () => import("/app/pages/secure/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeUkJKDsrF2Meta?.name ?? "secure-projects___en",
    path: indexeUkJKDsrF2Meta?.path ?? "/secure/projects",
    meta: indexeUkJKDsrF2Meta || {},
    alias: indexeUkJKDsrF2Meta?.alias || [],
    redirect: indexeUkJKDsrF2Meta?.redirect,
    component: () => import("/app/pages/secure/projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939CD2r2X6n4Meta?.name ?? "secure-quotes-id___en",
    path: _91id_939CD2r2X6n4Meta?.path ?? "/secure/quotes/:id()",
    meta: _91id_939CD2r2X6n4Meta || {},
    alias: _91id_939CD2r2X6n4Meta?.alias || [],
    redirect: _91id_939CD2r2X6n4Meta?.redirect,
    component: () => import("/app/pages/secure/quotes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexLdnizgkY94Meta?.name ?? "secure-quotes___en",
    path: indexLdnizgkY94Meta?.path ?? "/secure/quotes",
    meta: indexLdnizgkY94Meta || {},
    alias: indexLdnizgkY94Meta?.alias || [],
    redirect: indexLdnizgkY94Meta?.redirect,
    component: () => import("/app/pages/secure/quotes/index.vue").then(m => m.default || m)
  },
  {
    name: templatesEB5DgJAyeGMeta?.name ?? "secure-templates___en",
    path: templatesEB5DgJAyeGMeta?.path ?? "/secure/templates",
    meta: templatesEB5DgJAyeGMeta || {},
    alias: templatesEB5DgJAyeGMeta?.alias || [],
    redirect: templatesEB5DgJAyeGMeta?.redirect,
    component: () => import("/app/pages/secure/templates.vue").then(m => m.default || m)
  },
  {
    name: utilsoKlxMvFT6UMeta?.name ?? "secure-utils___en",
    path: utilsoKlxMvFT6UMeta?.path ?? "/secure/utils",
    meta: utilsoKlxMvFT6UMeta || {},
    alias: utilsoKlxMvFT6UMeta?.alias || [],
    redirect: utilsoKlxMvFT6UMeta?.redirect,
    component: () => import("/app/pages/secure/utils.vue").then(m => m.default || m)
  }
]