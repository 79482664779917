export const useAuthStore = defineStore('auth', () => {
  const refreshToken = ref('')
  const accessToken = ref('')
  const signedOut = ref(false)
  const refreshing = ref(false)
  const updating = ref(false)
  const signingOut = ref(false)
  const { errorToast } = useToast()
  const { signOut } = useSignOut()
  const router = useRouter()

  function resetTokens() {
    refreshToken.value = ''
    accessToken.value = ''
  }

  async function updateCurrentToken() {
    if (updating.value) {
      await new Promise<void>((resolve) => { // This promise is to wait until the token is updated
        const unwatch = watch(updating, (newVal) => {
          if (!newVal) {
            unwatch()
            resolve()
          }
        })
      })
      return
    }
    updating.value = true
    const headers = await useRequestHeaders(['cookie']) as HeadersInit
    const token = await $fetch('/api/auth/token', { headers })
    accessToken.value = token?.jwt ?? ''
    refreshToken.value = token?.refreshToken ?? ''
    refreshing.value = false
    updating.value = false
  }

  async function handleExpiredToken() {
    if (refreshing.value) {
      await new Promise<void>((resolve) => { // This promise is to wait until the token is refreshed
        const unwatch = watch(refreshing, (newVal) => {
          if (!newVal) {
            unwatch()
            resolve()
          }
        })
      })
      return true
    }

    refreshing.value = true
    const { signIn: handleRefreshToken } = useAuth()
    const response = await handleRefreshToken('refresh', {
      accessToken: accessToken.value,
      refreshToken: refreshToken.value,
      redirect: false,
    })

    if (!response || response.error) {
      await handleSessionExpired()
      return null
    }
    await updateCurrentToken()
    return true
  }

  async function handleSessionExpired() {
    if (signingOut.value)
      return

    signingOut.value = true
    await signOut({ redirect: false })
    router.push('/login')
    setTimeout(() => {
      errorToast('Session expired')
      signingOut.value = false
    }, 400)
  }

  return {
    refreshToken,
    accessToken,
    signedOut,
    updateCurrentToken,
    handleExpiredToken,
    handleSessionExpired,
    resetTokens,
  }
})
