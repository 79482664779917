const TRANSITIONS = {
  overlay: {
    enterFromClass: 'opacity-0 scale-75',
    enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
    leaveActiveClass: 'transition-opacity duration-150 ease-linear',
    leaveToClass: 'opacity-0',
  },
}

export default {
  root: ({ props }) => ({
    class: [
      'relative rounded-md',
      {
        'flex flex-col h-full': props.scrollable && props.scrollHeight === 'flex',
      },
    ],
  }),
  mask: {
    class: [
      // Position
      'absolute',
      'top-0 left-0',
      'z-20',

      // Flex & Alignment
      'flex items-center justify-center',

      // Size
      'w-full h-full',

      // Color
      'bg-surface-100/40 dark:bg-surface-800/40',

      // Transition
      'transition duration-200',
    ],
  },
  loadingIcon: 'w-14 h-14 text-bold text-primary animate-spin',
  tableContainer: ({ props }) => ({
    class: [
      {
        'relative rounded-xl mt-4 mb-4 border border-gray-200': props.scrollable,
        'flex flex-col grow h-full': props.scrollable && props.scrollHeight === 'flex',
      },
    ],
  }),
  header: ({ props }) => ({
    class: [
      'bg-white text-gray-500 border-gray-300 font-semibold py-5',
      'dark:border-primary-900/40 dark:text-white/80 dark:bg-gray-900', // Dark Mode
      props.showGridlines ? 'border-x border-t border-b-0' : 'border-0',
    ],
  }),
  table: 'w-full border-spacing-0',
  thead: ({ context }) => ({
    class: [
      {
        'bg-slate-50 top-0 z-[1]': context.scrollable,
      },
    ],
  }),
  tbody: ({ instance, context }) => ({
    class: [
      {
        'sticky z-[1]': instance.frozenRow && context.scrollable,
      },
    ],
  }),
  tfoot: ({ context }) => ({
    class: [
      {
        'bg-slate-50 bottom-0 z-[1]': context.scrollable,
      },
    ],
  }),
  footer: {
    class: [
      'bg-slate-50 text-slate-700 border-t-0 border-b border-x-0 border-gray-300 font-bold p-4',
      'dark:border-primary-900/40 dark:text-white/80 dark:bg-gray-900', // Dark Mode
    ],
  },
  column: {
    headerCell: ({ context, props }) => ({
      class: [
        'text-left border-0 border-b border-solid border-gray-300 dark:border-primary-900/40 font-semibold',
        'transition duration-200',
        context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-4', // Size
        context.sorted ? 'bg-primary-50 text-primary-700' : 'bg-gray-50 text-gray-500', // Sort
        context.sorted ? 'dark:text-white/80 dark:bg-primary-300' : 'dark:text-white/80 dark:bg-gray-900', // Dark Mode
        props.sortable ? 'cursor-pointer' : 'cursor-default', // Sortable
        {
          'sticky z-[1]': props.frozen || props.frozen === '', // Frozen Columns
          'border-x border-y': context?.showGridlines,
          'overflow-hidden space-nowrap border-y relative bg-clip-padding': context.resizable, // Resizable
        },
      ],
    }),
    columnHeaderContent: 'flex items-center font-medium text-base text-slate-500 capitalize',
    bodyCell: ({ props, context }) => ({
      class: [
        'text-left  text-black border-0 border-solid border-gray-200',
        context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-4', // Size
        'dark:text-white/80 dark:border-primary-900/40', // Dark Mode
        {
          'sticky bg-inherit': props.frozen || props.frozen === '', // Frozen Columns
          'border-x border-y': context?.showGridlines,
        },
      ],
    }),
    footerCell: ({ context }) => ({
      class: [
        'text-left border-0 border-b border-solid border-gray-300 font-bold',
        'bg-slate-50 text-slate-700',
        'transition duration-200',
        context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-4', // Size
        'dark:text-white/80 dark:bg-gray-900 dark:border-primary-900/40', // Dark Mode
        {
          'border-x border-y': context?.showGridlines,
        },
      ],
    }),
    sortIcon: ({ context }) => ({
      class: ['ml-2', context.sorted ? 'text-primary-700 dark:text-white/80' : 'text-gray-500 dark:text-white/70'],
    }),
    pcSortBadge: {
      class: [
        'flex items-center justify-center align-middle',
        'rounded-[50%] w-[1.143rem] leading-[1.143rem] ml-2',
        'text-primary-700 bg-primary-50',
        'dark:text-white/80 dark:bg-primary-400', // Dark Mode
      ],
    },
    columnFilter: 'inline-flex items-center ml-auto',
    filterOverlay: {
      class: [
        'bg-white text-gray-600 border-0 rounded-md min-w-[12.5rem]',
        'dark:bg-gray-900 dark:border-primary-900/40 dark:text-white/80', // Dark Mode
      ],
    },
    // filterMatchModeDropdown: { // TODO not found on docs
    //   root: 'min-[0px]:flex mb-2',
    // },
    filterConstraintList: 'm-0 p-0 py-3 list-none ',  
    filterConstraint: ({ context }) => ({
      class: ['m-0 py-3 px-5 bg-transparent p-5 border-b border-solid border-gray-300 dark:border-primary-900/40', 'transition duration-200', context?.highlighted ? 'text-primary-700 bg-primary-100 dark:text-white/80 dark:bg-primary-300' : 'text-gray-600 bg-transparent dark:text-white/80 dark:bg-transparent'],
    }),
    filterOperator: {
      class: [
        'px-5 py-3 border-b border-solid border-gray-300 text-slate-700 bg-slate-50 rounded-t-md',
        'dark:border-primary-900/40 dark:text-white/80 dark:bg-gray-900', // Dark Mode
      ],
    },
    pcFilterOperatorDropdown: {
      root: 'min-[0px]:flex',
    },
    filterRule: 'py-3 px-5',
    pcFilterAddRuleButton: {
      root: 'justify-center w-full min-[0px]:text-sm',
      label: 'flex-auto grow-0',
      icon: 'mr-2',
    },
    pcFilterRemoveRuleButton: {
      root: 'ml-2',
      label: 'grow-0',
    },
    filterButtonBar: 'flex items-center justify-between p-5',
    pcFilterClearButton: {
      root: 'w-auto min-[0px]:text-sm border-primary-500 text-primary-500 px-4 py-3',
    },
    pcFilterApplyButton: {
      root: 'w-auto min-[0px]:text-sm px-4 py-3',
    },
    // filterMenuButton: ({ context }) => ({ // TODO not found on  docs
    //   class: [
    //     'inline-flex justify-center items-center cursor-pointer no-underline overflow-hidden relative ml-2',
    //     'w-8 h-8 rounded-[50%]',
    //     'transition duration-200',
    //     'hover:text-slate-700 hover:bg-gray-300/20', // Hover
    //     'focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // Focus
    //     'dark:text-white/70 dark:hover:text-white/80 dark:bg-gray-900', // Dark Mode
    //     {
    //       'bg-primary-50 text-primary-700': context.active,
    //     },
    //   ],
    // }),
    // headerFilterClearButton: ({ context }) => ({ // TODO not found on docs
    //   class: [
    //     'inline-flex justify-center items-center cursor-pointer no-underline overflow-hidden relative',
    //     'text-left bg-transparent m-0 p-0 border-none select-none ml-2',
    //     {
    //       invisible: !context.hidden,
    //     },
    //   ],
    // }),
    columnResizer: 'block absolute top-0 right-0 m-0 w-2 h-full p-0 cursor-col-resize border border-transparent',
    // rowReorderIcon: 'cursor-move',  // TODO not found on docs
    pcRowEditorInit: {
      class: [
        'inline-flex items-center justify-center overflow-hidden relative',
        'text-left cursor-pointer select-none',
        'w-8 h-8 border-0 rounded-[50%]',
        'transition duration-200',
        'text-slate-700 border-transparent',
        'focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // Focus
        'hover:text-slate-700 hover:bg-gray-300/20', // Hover
        'dark:text-white/70', // Dark Mode
      ],
    },
    pcRowEditorSave: {
      class: [
        'inline-flex items-center justify-center overflow-hidden relative',
        'text-left cursor-pointer select-none',
        'w-8 h-8 border-0 rounded-[50%]',
        'transition duration-200',
        'text-slate-700 border-transparent',
        'focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // Focus
        'hover:text-slate-700 hover:bg-gray-300/20', // Hover
        'dark:text-white/70', // Dark Mode
      ],
    },
    pcRowEditorCancel: {
      class: [
        'inline-flex items-center justify-center overflow-hidden relative',
        'text-left cursor-pointer select-none',
        'w-8 h-8 border-0 rounded-[50%]',
        'transition duration-200',
        'text-slate-700 border-transparent',
        'focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // Focus
        'hover:text-slate-700 hover:bg-gray-300/20', // Hover
        'dark:text-white/70', // Dark Mode
      ],
    },
    // radioButtonWrapper: { // TODO not found on docs
    //   class: ['relative inline-flex cursor-pointer select-none align-bottom', 'w-6 h-6'],
    // },
    // radioButton: ({ context }) => ({ // TODO not found on docs
    //   class: [
    //     'flex justify-center items-center',
    //     'border-2 w-6 h-6 text-gray-700 rounded-full transition duration-200 ease-in-out',
    //     context.checked ? 'border-primary-500 bg-primary-500 dark:border-primary-400 dark:bg-primary-400' : 'border-gray-300 bg-white dark:border-primary-900/40 dark:bg-gray-900',
    //     {
    //       'hover:border-primary-500 dark:hover:border-primary-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
    //       'cursor-default opacity-60': context.disabled,
    //     },
    //   ],
    // }),
    // radioButtonIcon: ({ context }) => ({ // TODO not found on docs
    //   class: ['transform rounded-full', 'block w-3 h-3 transition duration-200 bg-white dark:bg-gray-900', {
    //     'backface-hidden scale-10 invisible': context.checked === false,
    //     'transform scale-100 visible': context.checked === true,
    //   }],
    // }),
    // headerCheckboxWrapper: { // TODO not found on docs
    //   class: ['cursor-pointer inline-flex relative select-none align-bottom', 'w-6 h-6'],
    // },
    // headerCheckbox: ({ context }) => ({ // TODO not found on docs
    //   class: [
    //     'flex items-center justify-center',
    //     'border-2 w-6 h-6 text-gray-600 rounded-lg transition-colors duration-200',
    //     context.checked ? 'border-primary-500 bg-primary-500 dark:border-primary-400 dark:bg-primary-400' : 'border-gray-300 bg-white dark:border-primary-900/40 dark:bg-gray-900',
    //     {
    //       'hover:border-primary-500 dark:hover:border-primary-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
    //       'cursor-default opacity-60': context.disabled,
    //     },
    //   ],
    // }),
    // headerCheckboxIcon: 'w-4 h-4 transition-all duration-200 text-white text-base dark:text-gray-900', // TODO not found on docs
    // checkboxWrapper: { // TODO not found on docs
    //   class: ['cursor-pointer inline-flex relative select-none align-bottom', 'w-6 h-6'],
    // },
    // checkbox: ({ context }) => ({ // TODO not found on docs
    //   class: [
    //     'flex items-center justify-center',
    //     'border-2 w-6 h-6 text-gray-600 rounded-lg transition-colors duration-200',
    //     context.checked ? 'border-primary-500 bg-primary-500 dark:border-primary-400 dark:bg-primary-400' : 'border-gray-300 bg-white dark:border-primary-900/40 dark:bg-gray-900',
    //     {
    //       'hover:border-primary-500 dark:hover:border-primary-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]': !context.disabled,
    //       'cursor-default opacity-60': context.disabled,
    //     },
    //   ],
    // }),
    // checkboxIcon: 'w-4 h-4 transition-all duration-200 text-white text-base dark:text-gray-900', // TODO not found on docs
    transition: TRANSITIONS.overlay,
  },
  bodyRow: ({ context, props }) => ({
    class: [
      context.selected ? 'bg-primary-50 text-gray-700 dark:bg-primary-300' : 'bg-white text-gray-600 dark:bg-gray-900',
      context.stripedRows ? (context.index % 2 === 0 ? 'bg-white text-gray-600 dark:bg-gray-900' : 'bg-primary-50/50 text-gray-600 dark:bg-gray-950') : '',
      'transition duration-200',
      'focus:outline focus:outline-[0.15rem] focus:outline-primary-500 focus:outline-offset-[-0.15rem]', // Focus
      'dark:text-white/80 dark:focus:outline dark:focus:outline-[0.15rem] dark:focus:outline-primary-300 dark:focus:outline-offset-[-0.15rem]', // Dark Mode
      // State
      { 'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50 ring-inset dark:focus:ring-primary-300/50': context.selectable },
      { 'hover:bg-surface-300/20': props.selectionMode && !context.selected },

      // Transition
      { 'transition duration-200': (props.selectionMode && !context.selected) || props.rowHover },

      // Misc
      { 'cursor-pointer': props.selectionMode },
    ],
  }),
  rowExpansion: 'bg-white text-gray-600 dark:bg-gray-900 dark:text-white/80',
  rowGroupHeader: {
    class: ['sticky z-[1]', 'bg-white text-gray-600', 'transition duration-200'],
  },
  rowGroupFooter: {
    class: ['sticky z-[1]', 'bg-white text-gray-600', 'transition duration-200'],
  },
  rowToggleButton: {
    class: [
      'text-left m-0 p-0 cursor-pointer select-none',
      'inline-flex items-center justify-center overflow-hidden relative',
      'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-[50%]',
      'transition duration-200',
      'dark:text-white/70', // Dark Mode
    ],
  },
  rowToggleIcon: 'inline-block w-4 h-4',
  columnResizeIndicator: 'absolute hidden w-px z-10 bg-primary-500 dark:bg-primary-300',
}
