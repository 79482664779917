export default {
    icon: {
      class: 'text-4xl mr-4',
    },
    rejectbutton: {
      root: {
        class: [
          'relative transform active:scale-95 transition-transform',
          'items-center inline-flex text-center align-bottom justify-center',
          'py-3 px-4 text-base leading-none',
          'rounded-lg',
          'text-surface-500 dark:text-surface-300',
          'bg-transparent border border-surface-200 hover:bg-surface-300/20 hover:border-surface-400/60',
          'hover:bg-surface-300/20',
        ],
      },
    },
  }
  