export default {
  root: {
    class: [
      // Shape
      'flex flex-col rounded-lg',
      // Color
      'bg-surface-0 dark:bg-surface-900',
      'text-surface-700 dark:text-surface-0',
      // Shadow
      'shadow-md',
    ],
    style: {
      'box-shadow': '0  5px 10px rgba(154,160,185,0.05), 0 15px 40px rgba(166,173,201,0.2)'
    }
  },
  body: {
    class: ['flex flex-col gap-4 p-6']
  },
  caption: {
    class: ['flex flex-col gap-2']
  },
  title: {
    class: 'text-2xl font-bold mb-2'
  },
  subtitle: {
    class: [
      'font-normal mb-2',
      'text-surface-600 dark:text-surface-0/60'
    ]
  },
  content: {
    class: 'py-5'
  },
  footer: {
    class: 'pt-5'
  }
}
