import type { MongoQuery, SubjectRawRule } from '@casl/ability'
import { useAbility } from '@casl/vue'

export function usePermissions() {
  const ability = useAbility()
  const { data } = useAuth()

  function updatePermissions() {
    const newAbilities: SubjectRawRule<string, string, MongoQuery>[] | null | undefined = data.value?.user?.abilities
    if (newAbilities)
      ability.update(newAbilities)
  }

  return {
    updatePermissions,
    ability,
  }
}
