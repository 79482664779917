// The following Tailwind theme aspires to be a reproduction of the
// default optional Genesis CSS theme that ships with FormKit

export default {
  // Global styles apply to _all_ inputs with matching section keys
  'global': {
    fieldset: 'max-w-md border border-gray-400 rounded px-2 pb-1',
    help: 'text-sm text-gray-500',
    inner: 'formkit-disabled:bg-gray-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
    input: 'appearance-none bg-transparent focus:outline-none focus:ring-0 focus:shadow-none',
    label: 'block capitalize mb-1 text-md text-slate-500',
    legend: 'font-bold capitalize text-md',
    loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
    message: 'text-red-500 mb-1 text-xs',
    messages: 'list-none p-0 mt-1 mb-0',
    outer: 'formkit-disabled:opacity-50',
    prefixIcon: 'w-10 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r border-gray-400 bg-white bg-gradient-to-b from-transparent to-gray-200 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon: 'w-7 pr-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto cursor-pointer',
  },

  // Family styles apply to all inputs that share a common family
  'family:box': {
    inner: '$reset formkit-inner formkit-disabled:bg-transparent formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
    decorator: 'cursor-pointer block relative h-5 w-5 mr-2 rounded bg-white ring-2 ring-gray-300 peer-checked:ring-primary hover:ring-primary text-transparent peer-checked:text-white peer-checked:bg-primary',
    decoratorIcon: 'flex p-[3px] w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
    help: 'mb-2 mt-1.5',
    input: 'absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
    label: '$reset text-base text-gray-700 mt-1 select-none',
    wrapper: 'flex items-center mb-1',
  },
  'family:button': {
    input: '$reset items-center transform active:scale-95 transition-transform capitalize inline-flex justify-center items-center bg-primary hover:bg-primary-700 text-white font-semibold py-3 px-4 formkit-disabled:bg-gray-400 formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin focus-visible:ring focus-visible:ring-yellow-500 focus-visible:ring-offset-2 focus-visible:outline-0 rounded-lg !leading-none',
    wrapper: '',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch',
  },
  'family:dropdown': {
    dropdownWrapper:
            'my-2 w-full shadow-lg rounded [&::-webkit-scrollbar]:hidden',
    emptyMessageInner:
            'flex items-center justify-center text-sm p-2 text-center w-full text-gray-500 [&>span]:mr-3 [&>span]:ml-0',
    inner:
            'max-w-md min-w-[120px] relative flex ring-1 ring-slate-200 focus-within:ring-primary-400 focus-within:ring-2 rounded-lg mb-1 formkit-disabled:focus-within:ring-gray-400 formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500',
    input: 'w-full px-3 py-2',
    listbox: 'bg-white shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem:
            'pl-7 relative hover:bg-gray-300 data-[is-active="true"]:bg-gray-300 aria-selected:bg-primary-600 aria-selected:text-white data-[is-active="true"]:aria-selected:bg-primary-600 data-[is-active="true"]:aria-selected:bg-primary-700 cursor-pointer',
    listitemGroup: `
      group/optgroup first:border-t-0 last:border-b-0 border-t border-b -mb-px border-$colorTemperature-$colorTemperatureStrength dark:border-$colorTemperature-$colorTemperatureStrengthDark
    `,
    groupLabel: `
      block pt-4 px-3 font-medium text-lg pointer-events-none text-$colorTemperature-$colorTemperatureStrength dark:text-$colorTemperature-$colorTemperatureStrengthDark
    `,
    loaderIcon: 'ml-auto',
    loadMoreInner:
            'flex items-center justify-center text-sm p-2 text-center w-full text-blue-500 formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
    option: 'px-3 py-2',
    optionLoading: 'pl-2.5 text-gray-400',
    placeholder: 'px-3 py-2 text-gray-400',
    selector:
            'flex w-full justify-between items-center min-h-[2.625em] [&u] cursor-pointer',
    selection: 'flex w-full',
    selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
    selectIcon:
            'flex box-content w-8 px-2 self-center grow-0 shrink-0 [&>svg]:w-[1em] cursor-pointer',
  },
  'family:text': {
    inner: 'flex items-center max-w-md bg-white ring-1 ring-slate-200 focus-within:ring-primary-400 focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded-lg mb-1',
    input: 'w-full px-3 py-2 border-none text-base text-gray-700',
  },

  // Specific styles apply only to a given input type
  'color': {
    inner: 'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
    input: '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
    suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
  },
  'file': {
    fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
    fileItemIcon: 'w-4 mr-2 shrink-0',
    fileList: 'shrink grow peer px-3 py-2 formkit-multiple:data-[has-multiple="true"]:mb-6',
    fileName: 'break-all grow text-ellipsis',
    fileRemove: 'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
    fileRemoveIcon: 'block text-base w-3 relative z-[2]',
    inner: 'relative max-w-md cursor-pointer formkit-multiple:[&>button]:absolute',
    input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
    noFiles: 'flex w-full items-center px-3 py-2 text-gray-400',
    noFilesIcon: 'w-4 mr-2',
  },
  'radio': {
    decorator: '$reset formkit-decorator cursor-pointer block relative h-5 w-5 mr-2 rounded ease-in-out rounded-full bg-white border border-gray-200 peer-checked/radio-input:border-none peer-checked/radio-input:bg-primary hover:border-primary',
    fieldset: 'border-none',
    legend: 'text-slate-500 font-normal text-md mb-2',
    decoratorIcon: 'w-5 p-[5px] text-white',
    input: 'border-2 w-6 h-6 text-gray-700 rounded-full transition duration-200 ease-in-out peer/radio-input',
    wrapper: 'cursor-pointer',
    option: 'cursor-pointer focus-within:ring-blue-500 focus-within:ring-2 hover:bg-secondary-dark',
  },
  'range': {
    inner: '$reset flex items-center max-w-md',
    input: '$reset w-full mb-1 h-2 p-0 rounded-full',
    prefixIcon: '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon: '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
  },
  'select': {
    inner: 'flex relative items-center rounded mb-1 ring-1 ring-gray-400 focus-within:ring-blue-500 focus-within:ring-2 [&>span:first-child]:focus-within:text-blue-500',
    input: 'w-full pl-3 pr-8 py-2 border-none text-base text-gray-700 placeholder-gray-400 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-400 formkit-multiple:data-[placeholder="true"]:text-inherit',
    selectIcon: 'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700',
  },
  'textarea': {
    inner: 'flex rounded-lg mb-1 bg-white ring-1 ring-slate-200 focus-within:ring-blue-500 [&>label:first-child]:focus-within:text-blue-500',
    input: 'block w-full h-32 px-3 py-3 border-none text-base text-gray-700 placeholder-gray-400 focus:shadow-outline',
  },

  // PRO input styles
  'autocomplete': {
    selectionWrapper: 'grow my-2 flex border items-center text-neutral-700 bg-slate-50 rounded-lg group-data-[multiple]:border group-data-[multiple]:border-neutral-300 group-data-[multiple]:mb-1.5 outline-none data-[active-selection=true]:ring-2 data-[active-selection=true]:ring-blue-500 dark:bg-neutral-600 dark:group-data-[multiple]:border-neutral-500 [&.formkit-dropZone]:opacity-25 [&.formkit-touchDropZone]:opacity-25 [&.formkit-touchDragging]:!flex [&.formkit-longTouch]:opacity-25',
    selections: 'flex flex-wrap gap-x-4 gap-y-1 mt-1.5',
    closeIcon: 'text-base w-4 relative m-auto',
    inner: '[&>div>[data-value]]:absolute [&>div>[data-value]]:mb-0',
    option: 'grow text-ellipsis',
    selection: 'static flex left-0 top-0 right-0 bottom-0 mt-0 rounded bg-slate-50',
    removeSelection: 'w-[2.5em] self-stretch text-base flex items-center text-neutral-700 hover:text-red-400 z-10 dark:text-neutral-300 cursor-pointer',
  },
  'mask': {
    inner: 'flex items-center max-w-md ring-1 ring-gray-400 focus-within:ring-blue-500 focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded mb-1',
    input: 'w-full px-3 py-2 border-none text-base text-gray-700 placeholder-gray-400',
  },
  'dropdown': {
    tagsWrapper: 'max-w-[calc(100%_-_35px)]',
    tags: 'flex items-center flex-wrap gap-1 mx-2 my-1.5',
    tag: 'flex items-center rounded-full bg-gray-200 text-xs text-black py-1 px-2.5 cursor-default',
    tagLabel: 'px-1',
    selectionsWrapper: 'flex w-[calc(100%_-_35px)] overflow-hidden',
    selections: 'inline-flex items-center px-2.5',
    selectionsItem: 'whitespace-nowrap mr-1 last:mr-0',
    truncationCount:
            'flex items-center whitespace-nowrap justify-center rounded text-white bg-gray-500 font-bold text-xs px-1 py-0.5',
    removeSelection: 'block w-2.5 my-1 cursor-pointer',
  },
  'rating': {
    inner: 'flex w-[130px] items-center relative',
    itemsWrapper: 'relative inline-flex focus:outline-2 focus:outline-$accentColor-$accentColorStrength',
    onItemRow: 'h-full w-full cursor-pointer',
    offItemRow: 'h-full w-full',
    onItemWrapper: '[&>*]:w-full [&>*]:h-full w-full h-full text-yellow-400 ',
    offItemWrapper: '[&>*]:w-full [&>*]:h-full w-full h-full text-gray-600 dark:text-text-gray-200',
    ratingItem: 'relative focus-within:outline focus-within:outline-2 focus-within:outline-$accentColor-$accentColorStrength w-[1.5em] h-[1.5em] ',
    itemLabelInner: 'h-px w-px overflow-hidden absolute white-space-nowrap ',
    itemLabel: 'absolute h-full ',
    ratingIcon: 'w-[1.5em] h-[1.5em] flex ',
    input: 'outline-none ',
    messages: 'mt-$spacing(-1) ',
  },
  'repeater': {
    content: 'grow p-3 flex flex-col align-center',
    controlLabel: 'absolute opacity-0 pointer-events-none',
    controls: 'flex flex-col items-center justify-center bg-gray-100 p-3',
    downControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
    fieldset: 'py-4 px-5',
    help: 'mb-2 mt-1.5',
    item: 'flex w-full mb-1 rounded border border-gray-200',
    moveDownIcon: 'block w-3 my-1',
    moveUpIcon: 'block w-3 my-1',
    removeControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
    removeIcon: 'block w-5 my-1',
    upControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
  },
  'slider': {
    outer: 'max-w-md',
    help: 'mt-0 mb-1',
    sliderInner:
            'flex items-center py-1 [&>.formkit-max-value]:mb-0 [&>.formkit-max-value]:ml-8 [&>.formkit-max-value]:shrink [&>.formkit-max-value]:grow-0 [&>.formkit-icon]:bg-none [&>.formkit-icon]:border-none [&>.formkit-icon]:p-0 [&>.formkit-icon]:w-4 [&>.formkit-prefix-icon]:mr-2 [&>.formkit-suffix-icon]:ml-2 [&[data-has-mark-labels="true"]_.formkit-track]:mb-4',
    track: 'grow relative z-[3] py-1 user-select-none',
    trackWrapper: 'px-[2px] rounded-full bg-gray-200',
    trackInner: 'h-[6px] mx-[2px] relative',
    fill: 'h-full rounded-full absolute top-0 mx-[-4px] bg-blue-500',
    marks: 'absolute pointer-events-none left-0 right-0 top-0 bottom-0',
    mark: 'absolute top-1/2 w-[3px] h-[3px] rounded-full -translate-x-1/2 -translate-y-1/2 bg-gray-400 data-[active="true"]:bg-white',
    markLabel:
            'absolute top-[calc(100%+0.5em)] left-1/2 text-gray-400 text-[0.66em] -translate-x-1/2',
    handles: 'm-0 p-0 list-none',
    handle:
            'group w-4 h-4 rounded-full bg-white absolute top-1/2 left-0 z-[2] -translate-x-1/2 -translate-y-1/2 shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1),0_1px_2px_0_rgba(0,0,0,0.8)] focus-visible:outline-0 focus-visible:ring-2 ring-blue-500 data-[is-target="true"]:z-[3]',
    tooltip:
            'absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-[4px] bg-blue-500 text-white py-1 px-2 text-xs leading-none whitespace-nowrap rounded-sm opacity-0 pointer-events-none transition-opacity after:content-[""] after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:-translate-y-[1px] after:border-4 after:border-transparent after:border-t-blue-500 group-hover:opacity-100 group-focus-visible:opacity-100 group-data-[show-tooltip="true"]:opacity-100',
    linkedValues: 'flex items-start justify-between',
    minValue:
            'grow max-w-[45%] mb-0 relative [&_.formkit-inner::after]:content-[""] [&_.formkit-inner::after]:absolute [&_.formkit-inner::after]:left-[105%] [&_.formkit-inner::after]:-translate-y-1/2 [&_.formkit-inner::after]:w-[10%] [&_.formkit-inner::after]:h-[1px] [&_.formkit-inner::after]:bg-gray-500',
    maxValue: 'grow max-w-[45%] mb-0 relative',
    chart:
            'relative z-[2] mb-2 flex justify-between items-center w-full aspect-[3/1]',
    chartBar:
            'absolute bottom-0 h-full bg-gray-400 opacity-[.66] data-[active="false"]:opacity-[.25]',
  },
  'taglist': {
    input: 'px-1 py-1 w-[0%] grow',
    removeSelection: 'w-2.5 mx-1 self-center text-gray-900 leading-none',
    tag: 'flex items-center my-1 p-1 bg-gray-200 text-xs rounded-full',
    tagWrapper: 'mr-1 focus:outline-none focus:text-white [&>div]:focus:bg-blue-500 [&>div>button]:focus:text-white',
    tagLabel: 'pl-2 pr-1',
    tags: 'flex items-center flex-wrap w-full py-1.5 px-2',
  },
  'toggle': {
    altLabel: 'block w-full mb-1 text-slate-500 text-md',
    inner: '$reset inline-block mr-2',
    input: 'peer fixed left-[999em] opacity-0 pointer-events-none',
    innerLabel: 'text-[10px] font-bold absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
    thumb: 'relative left-0 aspect-square rounded-full transition-all w-5 bg-gray-100',
    track: 'cursor-pointer p-0.5 min-w-[3em] relative rounded-full transition-all bg-gray-400 peer-checked:bg-primary-500 peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
    valueLabel: 'text-sm',
    wrapper: 'flex flex-wrap items-center mb-1',
  },
  'togglebuttons': {
    // inherits family:button classes
    options: `
      group/options
      inline-flex
      data-[vertical="true"]:flex-col
    `,
    option: `
      group/option
    `,
    input: `
      !px-$spacing(4,*)
      group-data-[vertical="true"]/options:w-full
      justify-center
  
      disabled:opacity-50
      disabled:cursor-not-allowed
      group-data-[disabled]:disabled:opacity-100
  
      border-2 border-secondary-200 
      hover:border-secondary-400/60
      group-data-[vertical="false"]/options:border-r-0
      group-data-[vertical="true"]/options:border-b-0
      aria-[pressed="false"]:bg-white
      aria-[pressed="false"]:text-slate-500
      aria-[pressed="true"]:bg-primary
      aria-[pressed="true"]:text-white
      aria-[pressed="true"]:border-primary
      dark:aria-[pressed="false"]:bg-black
      dark:aria-[pressed="false"]:text-white
      dark:aria-[pressed="true"]:bg-white
      dark:aria-[pressed="true"]:text-black
   
      group-[]/option:rounded-none
      group-data-[vertical="false"]/options:group-last/option:border-r-2
      group-data-[vertical="true"]/options:group-last/option:border-b-2
    `,
  },
  'transferlist': {
    outer: `
      [&_.dnd-placeholder]:bg-blue-500 [&_.dnd-placeholder]:text-white
      [&_.dnd-placeholder_svg]:text-white
      [&_.dnd-children-hidden]:w-full [&_.dnd-children-hidden]:p-0 [&_.dnd-children-hidden]:flex [&_.dnd-children-hidden]:flex-col [&_.dnd-children-hidden]:border-none
      [&_.dnd-children-hidden_span]:hidden
      [&_.dnd-children-hidden_.formkit-transferlist-option]:hidden
      [&_.dnd-multiple-selections_span]:inline-block
      [&_.dnd-multiple-selections_.formkit-transferlist-option]:inline-block
    `,
    fieldset: '$reset max-w-2xl',
    wrapper:
            'flex max-h-[350px] flex-col sm:flex-row justify-between w-full max-w-none',
    help: 'pb-2',
    transferlist:
            'sm:w-3/5 shadow-md flex flex-col min-h-[350px] max-h-[350px] border rounded overflow-hidden select-none bg-gray-50',
    transferlistHeader:
            'flex bg-gray-100 justify-between items-center border-b p-3',
    transferlistHeaderItemCount: 'ml-auto text-sm',
    transferlistListItems:
            'list-none bg-gray-50 h-full sm:max-w-xs overflow-x-hidden overflow-y-auto',
    transferlistListItem:
            'pl-8 relative aria-selected:bg-blue-600 aria-selected:data-[is-active=true]:bg-blue-600 aria-selected:text-white aria-selected:data-[is-active=true]:text-white first:-mt-px first:border-t py-2 px-3 flex relative border-b bg-white data-[is-active=true]:text-blue-500 data-[is-active=true]:bg-gray-100 cursor-pointer group-data-[is-max=true]:cursor-not-allowed items-center',
    transferlistOption: 'text-sm',
    transferControls:
            'flex sm:flex-col justify-center mx-auto my-2 sm:mx-2 sm:my-auto border rounded',
    transferlistButton:
            'text-sm disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-50 first:rounded-l last:rounded-r sm:first:rounded-t sm:last:rounded-b appearance-none p-2 m-0 cursor-pointer h-10 border-none rounded-none bg-gray-50 hover:outline disabled:hover:outline-none hover:outline-1 hover:outline-black hover:text-blue-500 disabled:hover:text-current hover:z-10',
    sourceEmptyMessage:
            'appearance-none border-none w-full p-0 m-0 text-center text-gray-500 italic',
    sourceListItems: 'group-data-[is-max=true]:opacity-50',
    targetEmptyMessage:
            'appearance-none border-none w-full p-0 m-0 text-center text-gray-500 italic',
    emptyMessageInner: 'flex items-center justify-center p-2 text-sm',
    transferlistControls: 'bg-white px-3 py-2 border-b',
    transferlistSearch: 'flex border rounded items-center',
    transferlistSearchInput:
            'border-none p-1 w-full bg-transparent outline-none text-sm',
    controlLabel: 'hidden',
    selectedIcon: 'w-3 absolute left-3 select-none',
    fastForwardIcon:
            'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
    moveRightIcon:
            'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
    moveLeftIcon:
            'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
    rewindIcon:
            'w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0',
  },
  'search': {
    outer: '!h-full',
    wrapper: 'h-full',
    label: ``,
    prefixIcon: 'from-white to-white !rounded-lg !border-r-0',
    suffixIcon: ``,
    inner: `lg:!min-w-[22rem] h-full !ring-0 !border !border-gray-300 rounded-lg !mb-0`,
    input: '!pl-0 font-normal mt-[2px]',
    help: ``,
    messages: ``,
    message: ``,
  },
}
