export default {
    root: ({ props }) => ({
      class: [
        'inline-flex items-center justify-center m-0 text-xs font-semibold px-3 py-1 capitalize',
        {
          'bg-blue-50 text-primary': !props.severity || props.severity === 'primary',
          'bg-green-50 text-green-700': props.severity === 'success',
          'bg-blue-50 text-blue-700': props.severity === 'info',
          'bg-orange-50 text-orange-700': props.severity === 'warning',
          'bg-red-50 text-red-700': props.severity === 'danger',
          'bg-gray-100 text-gray-700': props.severity === 'plain',
          'bg-indigo-50 text-indigo-700': props.severity === 'indigo',
        },
        {
          'rounded': !props.rounded,
          'rounded-full': props.rounded,
        },
      ],
    }),
    value: 'leading-6',
    icon: 'mr-1 text-sm',
  }
  