// formkit custom validation for cron expression

import cronstrue from 'cronstrue'

export const cron = function (node) {
  try {
    cronstrue.toString(
      node.value,
      {
        use24HourTimeFormat: true,
        verbose: true,
        throwExceptionOnParseError: true,
      },
    )
    return true
  }
  catch (error) {
    return false
  }
}
