<script setup lang="ts">
const props = defineProps({
  error: {
    type: Object,
    default: () => {},
  },
})
const router = useRouter()

onMounted(() => {
  console.error(props.error.message)
  console.error(props.error.stack)
})
</script>

<template>
  <main class="font-sans antialiased bg-white dark:bg-black text-black dark:text-white grid min-h-screen place-content-center overflow-hidden ">
    <div class="fixed left-0 right-0 spotlight z-10" />
    <div class="max-w-520px text-center z-20">
      <h1 class="text-8xl sm:text-10xl font-medium mb-8">
        404
      </h1>
      <p class="text-xl px-8 sm:px-0 sm:text-4xl font-light mb-16 leading-tight">
        Sorry, the page you are looking for could not be found.
      </p>
      <div class="w-full flex items-center justify-center">
        <button
          class="gradient-border text-md sm:text-xl py-2 px-4 sm:py-3 sm:px-6 cursor-pointer"
          @click="router.back()"
        >
          Go back
        </button>
      </div>
    </div>
  </main>
</template>

<style>
.spotlight {
  background: linear-gradient(45deg, #efd048 0%, #99bbd2 50%, #1a679a 100%);
  filter: blur(20vh);
  height: 40vh;
  bottom: -30vh;
}
.gradient-border {
  position: relative;
  border-radius: 0.5rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
@media (prefers-color-scheme: light) {
  .gradient-border {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .gradient-border::before {
    background: linear-gradient(90deg, #e2e2e2 0%, #e2e2e2 25%, #efd048 50%, #99bbd2 75%, #1a679a 100%);
  }
}
@media (prefers-color-scheme: dark) {
  .gradient-border {
    background-color: rgba(20, 20, 20, 0.3);
  }
  .gradient-border::before {
    background: linear-gradient(90deg, #303030 0%, #303030 25%, #efd048 50%, #99bbd2 75%, #1a679a 100%);
  }
}
.gradient-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.5rem;
  padding: 2px;
  width: 100%;
  background-size: 400% auto;
  opacity: 0.5;
  transition: background-position 0.3s ease-in-out, opacity 0.2s ease-in-out;
  -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
  mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.gradient-border:hover::before {
  background-position: -50% 0;
  opacity: 1;
}
</style>
