import Lara from '@/assets/presets/lara'
import Aura from '@/assets/presets/aura'
import AvantUi from '@/assets/presets/avantui'

const $appStatePlugin = {
  install: (app) => {
    const _appState = reactive({ preset: 'avantui', darkMode: false })

    watch(
      () => _appState.preset,
      (newValue) => {
        switch (newValue) {
          case 'avantui':
            app.config.globalProperties.$primevue.config.pt = AvantUi
            break
          case 'lara':
            app.config.globalProperties.$primevue.config.pt = Lara
            break
          case 'aura':
            app.config.globalProperties.$primevue.config.pt = Aura
            break
        }
      },
    )

    app.config.globalProperties.$appState = _appState
  },
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use($appStatePlugin)
})
