export default {
  root: ({ props, context }) => ({
    class: [
      'relative',
      // Alignment
      'flex items-center justify-center',
      'py-2.5 px-4',
      'rounded-md border',
      // Color
      {
        'bg-surface-0 dark:bg-surface-900 text-surface-700 dark:text-white/80': !context.active,
        'bg-primary-500 dark:bg-primary-400 border-primary-500 dark:border-primary-400 text-white dark:text-surface-900': context.active
      },
      // States
      {
        'hover:bg-surface-50 dark:hover:bg-surface-800/80': !props.disabled && !props.modelValue,
        'focus:outline-none focus:ring focus:ring-primary-400/50 dark:focus:ring-primary-300/50': !props.disabled
      },
      // Invalid State
      {
        'border-red-500 dark:border-red-400': props.invalid,
        'border-surface-100 dark:border-surface-950': !props.invalid
      },
      // Before
      'before:absolute before:left-1 before:top-1 before:w-[calc(100%-0.5rem)] before:h-[calc(100%-0.5rem)] before:rounded-[4px] before:z-0',
      // Transitions
      'transition-all duration-200',
      // Misc
      { 'cursor-pointer': !props.disabled, 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
    ]
  }),
  content: 'relative items-center inline-flex justify-center gap-2',
  label: 'font-bold leading-[normal] text-center w-full z-10 relative',
  icon: 'relative z-10 mr-2'
}