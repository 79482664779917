import navItems from '@/definitions/moduleDefinitions'

export function useHierarchicalPermissions() {
  const { ability } = usePermissions()

  function isRouteAllowed(path: string | undefined) {
    if (!path)
      return false

    const cleanedPath = path.replace(/^\/secure\/?/, '').trim()
    const splittedPath = cleanedPath.split('/')

    if (!cleanedPath.length)
      return ability.can('read', 'dashboard')

    if (splittedPath[0] === 'create')
      return ability.can('edit', 'projects') || ability.can('edit', 'quotes')

    if (splittedPath.length === 1)
      return ability.can('read', splittedPath[0])

    for (const [index, entity] of splittedPath.entries()) {
      const subject = splittedPath.slice(0, index + 1).join('-')
      if (ability.can('read', subject))
        return true
    }
    return false
  }

  const parentRoutesItems = computed(() => {
    return navItems.filter(item => !!item.group)
  })

  const defaultRedirectRoute = computed(() => {
    let fullPath = '/secure'

    // if have access to dashboard this will be always the first option
    if (ability.can('read', 'dashboard'))
      return fullPath

    // check for all parent routes
    for (const [index, navItem] of parentRoutesItems.value.entries()) {
      if (ability.can('read', navItem.group as string)) {
        fullPath += `/${navItem.to}`
        return fullPath
      }
    }

    // if no permissions for parent routes, return path based o abilities
    const rules = ability.rules
    for (const rule of rules) {
      const rulePath = (rule.subject as string).replace('-', '/')
      const availableNavItem = navItems.find(item => item.to === rulePath)
      if (availableNavItem)
        return `/secure/${availableNavItem.to}`
    }
    return fullPath
  })

  const isAdmin = computed(() => ability.can('manage', 'all'))

  return { isRouteAllowed, defaultRedirectRoute, parentRoutesItems, isAdmin }
}
