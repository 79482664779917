import blockui from "./blockui";
import button from "./button";
import card from "./card";
import checkbox from "./checkbox";
import confirmdialog from "./confirmdialog";
import confirmpopup from "./confirmpopup";
import datepicker from "./datepicker";
import dialog from "./dialog";
import menu from "./menu";
import message from "./message";
import overlaypanel from "./overlaypanel";
import progressspinner from "./progressspinner";
import selectbutton from "./selectbutton";
import tag from "./tag";
import toast from "./toast";
import datatable from "./datatable";
import togglebutton from "./togglebutton";
import tooltip from "./tooltip";
import inputnumber from "./inputnumber";
import inputotp from "./inputotp";
import inputtext from "./inputtext";
import listbox from "./listbox";
import progressbar from "./progressbar";
import paginator from "./paginator";
import chip from "./chip";
import skeleton from "./skeleton";
import tab from "./tab";
import tabs from "./tabs";
import tablist from "./tablist";
import tabpanel from "./tabpanel";
import tabpanels from "./tabpanels";
import popover from "./popover";
import speeddial from "./speeddial";
import drawer from "./drawer";
import divider from "./divider";
import avatar from "./avatar";
import badge from "./badge";
import badgedirective from "./badgedirective";

export default {
    button,
    selectbutton,
    togglebutton,
    card,
    toast,
    confirmpopup,
    tag,
    checkbox,
    dialog,
    confirmdialog,
    overlaypanel,
    menu,
    message,
    progressspinner,
    blockui,
    datepicker,
    datatable,
    inputnumber,
    inputotp,
    inputtext,
    listbox,
    progressbar,
    paginator,
    skeleton,
    popover,
    chip,
    tab,
    tabs,
    tablist,
    tabpanel,
    tabpanels,
    speeddial,
    drawer,
    divider,
    avatar,
    badge,
    directives: {
        tooltip,
        badgedirective
    }
};
