export default {
  root: 'w-full inline-flex',
  input: ({ props }) => ({
    class: [
      'focus:outline-none focus:ring-0 focus:shadow-none',
      { 'rounded-tr-none rounded-br-none order-2': props.showButtons && props.buttonLayout === 'stacked' },
      {
        'order-2': props.showButtons && props.buttonLayout === 'horizontal',
      },
    ],
  }),
  buttongroup: ({ props }) => ({
    class: [{ 'flex flex-col': props.showButtons && props.buttonLayout === 'stacked' }],
  }),
  incrementbutton: ({ props }) => ({
    class: [
      'flex !items-center !justify-center',
      {
        'rounded-br-none rounded-bl-none rounded-bl-none !p-0 flex-1 w-[3rem] order-3': props.showButtons && props.buttonLayout === 'stacked',
      },
      {
        'order-3': props.showButtons && props.buttonLayout === 'horizontal',
      },
    ],
  }),
  label: 'hidden',
  decrementbutton: ({ props }) => ({
    class: [
      'flex !items-center !justify-center',
      {
        'rounded-tr-none rounded-tl-none rounded-tl-none !p-0 flex-1 w-[3rem]': props.showButtons && props.buttonLayout === 'stacked',
      },
      {
        'order-1': props.showButtons && props.buttonLayout === 'horizontal',
      },
    ],
  }),
}
